<template>
  <div class="land">
    <TopMenu />
    <FirstScreen />
    <DeepMove />
    <GoCraft/>
    <EMYC />
    <EMYCCollection />
    <StepnGuide />
    <TheTeam />
    <ContactUs />
  </div>
</template>

<script setup>
import FirstScreen from "@/views/FirstScreen.vue";
import DeepMove from "./views/DeepMove.vue";
import TopMenu from "@/components/TopMenu.vue";
import EMYC from "@/views/EMYC.vue";
import EMYCCollection from "./views/EMYCCollection.vue";
import { onMounted, ref } from "vue";
import StepnGuide from "./views/StepnGuide.vue";
import TheTeam from "./views/TheTeam.vue";
import ContactUs from "./views/ContactUs.vue";
import GoCraft from "./views/GoCraft.vue";

const screenHeight = ref();

onMounted(async () => {
  function onResize() {
    const menu = document.getElementById("topmenu");
    screenHeight.value = window.innerHeight + menu.offsetHeight;
  }
  // window.addEventListener("resize", onResize);
  onResize();
});
</script>

<style lang="scss">
$font-color: #fff;
$dark-bg: #10161d;
$alpha-bg: #10161da4;
$sec-color: #fc9f17;
$grey-font: #3e3e3e;
$filter-color: rgb(0 99 254 / 45%);
$footer: #000;
$text-line-height: 1.2;
$header-line-height: 1;
.land {
  background-color: $dark-bg;
}
body {
  font-size: 1.2rem;
  font-weight: 300;
  line-height: $text-line-height;
}
.container,
.container-fluid {
  overflow: hidden !important;
}
* {
  overflow-x: clip;
}
#content {
  position: relative;
}
.block {
  height: v-bind("screenHeight+'px'");
}
.main-screen {
  @media (min-width: 576px) {
    background: url("@/assets/main-screen.jpg") center no-repeat;
    background-size: cover;
  }
  @media (max-width: 576px) {
    background: url("@/assets/main-screen-m.jpg") center no-repeat;
    background-size: cover;
  }

  .about {
    margin-bottom: 7rem;
    text-align: center;
    padding: 2rem;
    background: none;
  }
}
.land {
  color: $font-color;
  overflow-y: scroll a {
    text-decoration: none;
  }
  a:hover {
    text-decoration: none;
    color: none;
  }
}

.logo {
  @media (min-width: 576px) {
    height: 3rem;
  }
  @media (max-width: 576px) {
    height: 2rem;
  }
}
.menu {
  background: $dark-bg;
  text-transform: uppercase;
}
a.underline {
  border-bottom: 1px solid;
  color: $sec-color;
  text-decoration: none;
}
a.underline:hover {
  color: $sec-color;
}

.about {
  font-size: 1.2rem;
  font-weight: 300;
  line-height: $text-line-height;
  background: $alpha-bg;
  padding: 2rem;
}

.deep-move {
  .animation {
    position: absolute;
    right: 0;
    top: 8rem;
  }
  .mobile .animation {
    left: 3rem;
    top: 5rem;
  }
  background: url("@/assets/deep-move.jpg") right no-repeat;
  background-size: cover;
  .slogan {
    line-height: $header-line-height;
    font-size: 8rem;
    font-weight: bold;
    z-index: 1;
  }
  .deepmove-logo img {
    height: 4rem;
  }
  .deep-move-logo-mobile img {
    height: 2.5rem;
  }
  .mobile-slogan {
    color: $font-color;
    text-align: right;
  }
}
.mobile-slogan {
  line-height: $text-line-height;
  font-size: 3rem;
  font-weight: normal;
  color: $sec-color;
}
.EMYC {
  background: $dark-bg;
  .row .img-center {
    top: 40%;
  }
  font-size: 1.2rem;
  font-weight: 300;
  line-height: $text-line-height;
  .deco-mobile {
    width: 6.5rem;
    right: 1rem;
    top: 5rem;
    position: absolute;
    overflow: hidden;
  }
  .slogan {
    line-height: $header-line-height;
    font-size: 8rem;
    font-weight: normal;
    color: $sec-color;
    .text {
      z-index: 99;
    }
    .deco-1 {
      right: 6rem;
      bottom: 5rem;
    }
    .deco-2 {
      right: 10rem;
      bottom: 2rem;
    }
    .deco-3 {
      right: 19.5rem;
      bottom: 1rem;
    }
  }
  .mobile-deco-1 {
    right: 1rem;
    bottom: 4rem;
  }
  .mobile-deco-2 {
    right: 3rem;
    bottom: 10rem;
  }
  .mobile-deco-3 {
    right: 6rem;
    bottom: 7rem;
  }
}
.deco-round {
  position: absolute;
  width: 8rem;
  height: 8rem;
  border-radius: 100%;
  border: 2px solid $sec-color;
}
.deco-round-mobile {
  position: absolute;
  width: 5rem;
  height: 5rem;
  border-radius: 100%;
  border: 2px solid $sec-color;
  top: 5rem;
}
.deco-uni {
  position: absolute;
}
.action-button {
  background: $sec-color;
  padding: 5px 5rem;
  border-radius: 3rem;
  font-size: 2rem;
  font-weight: bold;
  color: $font-color;
  text-decoration: none;
}
.action-button:hover {
  color: $font-color;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
    animation-timing-function: cubic-bezier(0.34, 1.61, 0.7, 1);
  }
  100% {
    transform: rotate(360deg);
  }
}
.spin-it-0 {
  animation: spin 10s infinite linear;
  backface-visibility: hidden;
  animation-delay: 0;
}
.spin-it-1 {
  animation: spin 10s infinite linear;
  backface-visibility: hidden;
  animation-delay: 1500ms;
}
.spin-it-2 {
  animation: spin 10s infinite linear;
  backface-visibility: hidden;
  animation-delay: 1000ms;
}
.spin-it-3 {
  animation: spin 10s infinite linear;
  backface-visibility: hidden;
  animation-delay: 2000ms;
}
.spin-it-4 {
  animation: spin 10s infinite linear;
  backface-visibility: hidden;
  animation-delay: 3000ms;
}
.spin-it-5 {
  animation: spin 10s infinite linear;
  backface-visibility: hidden;
  animation-delay: 4000ms;
}
.spin-it-6 {
  animation: spin 10s infinite linear;
  backface-visibility: hidden;
  animation-delay: 5000ms;
}
.spin-it-7 {
  animation: spin 10s infinite linear;
  backface-visibility: hidden;
  animation-delay: 6000ms;
}
.spin-it-8 {
  animation: spin 10s infinite linear;
  backface-visibility: hidden;
  animation-delay: 7000ms;
}
#gocraft {
  .img-center{
    width: 30rem;
    border-radius: 2rem;
  }
  .slogan {
    line-height: $header-line-height;
    font-size: 7rem;
    font-weight: normal;
    color: $sec-color;
  }
  .deco-round {
    left: 5rem;
    top: 15rem;
  }
  .deco-1 {
    left: 3rem;
    top: 21rem;
  }
  .deco-2 {
    left: 7rem;
    margin-top: 10rem;
  }
  .action-button {
    font-size: 2rem;
    padding: 5px 1rem;
  }
  .deco-round-mobile {
    top: 21rem;
    left:3rem;
  }
  .mobile-deco-1 {
    left: 4rem;
    top: 25rem;
  }
  .mobile-deco-2 {
    left: 1rem;
    top: 21rem;
  }
}
#collection{
  font-size: 1.2rem;
  font-weight: 300;
  line-height: $text-line-height;
  background: $dark-bg;
  .slogan {
    line-height: $header-line-height;
    font-size: 5rem;
    font-weight: normal;
    color: $sec-color;
  }
  .deco-round {
    left: 2rem;
    top: 10rem;
  }
  .deco-1 {
    left: 0;
  }
  .deco-2 {
    left: 5rem;
    margin-top: 7rem;
  }
  .action-button {
    font-size: 2rem;
    padding: 5px 1rem;
  }
  .deco-mobile {
    width: 5.5rem;
    left: 4rem;
    top: 6rem;
    position: absolute;
    overflow: hidden;
  }
  .deco-round-mobile {
    top: 13rem;
    left: 9rem;
  }
  .mobile-deco-1 {
    left: 4rem;
    top: 15rem;
  }
  .mobile-deco-2 {
    left: 14rem;
    top: 18rem;
  }
}
.row .img-center {
  filter: brightness(30%);
  position: absolute;
  width: 25rem;
  left: 30%;
  top: 20%;
}
.guide {
  z-index: 2;
  position: relative;
}
#guide {
  font-size: 1.2rem;
  font-weight: 300;
  line-height: $text-line-height;
  background: $dark-bg;
  @media (min-width: 576px) {
    .slogan {
      font-size: 5rem;
    }
    .deco-round {
      bottom: 0;
      left: 8rem;
      width: 7rem;
      height: 7rem;
    }
    .img-center {
      width: auto;
      left: 13%;
      top: 34%;
    }
    .deco {
      margin: 0;
    }
    .deco-1 {
      left: 22rem;
      top: 8rem;
    }
    .deco-2 {
      left: 16rem;
      bottom: 4rem;
    }
    .deco-3 {
      left: 18rem;
      top: 5rem;
    }
  }
  @media (max-width: 576px) {
    .slogan {
      font-size: 3rem;
    }
    .deco-round {
      bottom: 0;
      left: 0rem;
      width: 6rem;
      height: 6rem;
    }
    .img-center {
      min-width: 760px;
      left: -48%;
      top: 65%;
      rotate: 16deg;
    }
    .deco {
      margin-right: -5rem;
    }
    .deco-1 {
      left: 10rem;
      top: 5rem;
    }
    .deco-2 {
      left: 7rem;
      bottom: 1rem;
    }
    .deco-3 {
      left: 1rem;
    }
  }

  .slogan {
    color: $sec-color;
    text-align: right;
    line-height: $header-line-height;
  }
  .action-button {
    font-size: 2rem;
    padding: 5px 1rem;
  }
  .text {
    text-align: right;
  }
}
#team {
  background: $dark-bg;
  @media (max-width: 576px) {
    .slogan {
      font-size: 2rem;
      margin-top: 5rem;
    }
    .elipse img {
      position: absolute;
      top: 0.5rem;
      rotate: -7deg;
      right: -1rem;
      width: 7rem;
    }
    .user-role {
      margin-bottom: 4rem;
    }
  }
  @media (min-width: 576px) {
    .slogan {
      font-size: 5rem;
      font-weight: bold;
    }
    .elipse img {
      right: -2rem;
      position: absolute;
      rotate: -7deg;
      top: 1rem;
      width: 17rem;
    }
  }
  .user-role img {
    border-radius: 100%;
  }
  .mobile img {
    width: 6rem;
  }
  .desktop img{
    width: 10rem;
  }
  .elipse {
    overflow: visible;
  }
  .elipse div {
    z-index: 2;
    position: relative;
  }
  .slogan {
    color: $sec-color;
    text-transform: uppercase;
  }
  .left {
    rotate: 180deg;
  }
  .team {
    margin-top: 4rem;
    font-weight: bold;
    .nick {
      font-size: 2rem;
      text-transform: uppercase;
      color: $sec-color;
    }
    .role {
      color: $grey-font;
    }
    .dots {
      position: absolute;
      right: 0;
      top: 15rem;
      width: 7rem;
      z-index: 1;
    }

    hr {
      color: $grey-font;
    }
  }
}
#contact {
  background: $footer;
  .email a {
    color: $font-color;
    text-decoration: none;
  }
  @media (min-width: 576px) {
    .slogan {
      color: $sec-color;
      font-size: 5rem;
      margin-top: 10rem;
    }
    .email {
      margin-top: 5rem;
      font-size: 3rem;
      margin-right: 3rem;
    }
    .arrow {
      margin-top: 5rem;
      width: 6rem;
    }
    .footer {
      padding: 4rem 0;
      color: $grey-font;
    }
  }
  @media (max-width: 576px) {
    .slogan {
      color: $sec-color;
      font-size: 3rem;
      margin-top: 10rem;
    }
    .email {
      margin-top: 2rem;
      font-size: 1.5rem;
      margin-right: 2rem;
    }
    .arrow {
      margin-top: 2rem;
      width: 3rem;
      img {
        width: 2rem;
      }
    }
    .footer {
      padding-bottom: 1rem;
    }
  }
  .slogan {
    overflow: visible;
  }
  .arrow {
    overflow: visible;
    img {
      rotate: 90deg;
    }
  }
  .footer {
    font-size: 1rem;
    color: $grey-font;
    line-height: $header-line-height;
  }
  .dots {
    position: absolute;
    bottom: -5rem;
  }
  .anime {
    position: absolute;
    font-size: 0;
    right: -2rem;
    bottom: 3rem;
  }
  .text {
    margin-left: 2rem;
  }
  .footer-img {
    height: 400px;
    background: url("@/assets/footer.jpg") right no-repeat;
    background-size: cover;
  }
}
</style>

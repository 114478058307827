<template>
  <div id="team">
    <hr class="d-sm-block d-none" />
    <div class="container ">
      <div class="d-flex flex-column block justify-content-center">
      <div class="row">
        
        <div class="col-12 slogan">
          <div class="d-flex justify-content-around align-items-center">
            <div data-aos-anchor="#team"  data-aos="fade-right" data-aos-delay="400"><img :src="require('@/assets/arrow.svg')" /></div>
            <div class="position-relative elipse">
              <div data-aos-anchor="#team"  data-aos="zoom-in">Meet the team</div>
              <img mg data-aos-anchor="#team"  data-aos="zoom-out" data-aos-delay="800" data-aos-duration="1000" :src="require('@/assets/elipse.svg')" />
            </div>
            <div><img data-aos-anchor="#team"  data-aos="fade-right" data-aos-delay="400" class="left" :src="require('@/assets/arrow.svg')" /></div>
          </div>
        </div>
      </div>
      <div class="row team position-relative">
        <img data-aos-anchor="#team"  data-aos="fade-left" data-aos-delay="1000" class="dots" :src="require('@/assets/dots.svg')" />

        <div class="col-md-3 col-12 d-none d-sm-block" v-for="member of team" :key="member.name">
          <div class="d-flex justify-content-center desktop">
            <div class="d-flex position-relative align-items-center flex-column user-role" data-aos-anchor="#team"  data-aos="fade-down" :data-aos-delay="member.delay">
              <div class="mb-2"><img :src="require(`@/assets/${member.name}.jpg`)" /></div>
              <div data-aos-anchor="#team"  data-aos="fade-right" :data-aos-delay="member.delay+200" class="nick">{{ member.name }}</div>
              <div data-aos-anchor="#team"  data-aos="fade-left" :data-aos-delay="member.delay+200" class="role">{{ member.role }}</div>
              <div><hr data-aos-anchor="#team"  data-aos="fade-up" :data-aos-delay="member.delay+400"></div>
            </div>
          </div>
        </div>
        <div class="col-12 d-block d-sm-none" v-for="member of team" :key="member.name">
          <div class="d-flex mobile">
            <div class="d-flex align-items-center justify position-relative user-role flex-fill" data-aos-anchor="#team"  data-aos="fade-down" :data-aos-delay="member.delay">
              <div class="me-4 ms-4"><img :src="require(`@/assets/${member.name}.jpg`)" /></div>
              <div class="d-flex flex-column justify-content-center"><div data-aos-anchor="#team"  data-aos="fade-right" :data-aos-delay="member.delay+200" class="nick">{{ member.name }}</div>
              <div data-aos-anchor="#team"  data-aos="fade-left" :data-aos-delay="member.delay+200" class="role">{{ member.role }}</div></div>  

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script setup>
const team = [
  {
    name: "datos",
    role: "Vision & Strategy",
    delay: 200
  },
  {
    name: "formobr",
    role: "Frontend & Backend",
    delay: 300
  },
  {
    name: "kiryl",
    role: "Data & Analytics",
    delay: 400
  },
  {
    name: "sugar",
    role: "Design & Community",
    delay: 500
  },
];
</script>

<template>
  <div data-aos="fade-in" class="main-screen block d-flex align-items-end">
    <div class="container">
      <div class="row">
        <div class="d-flex justify-content-center">
          <div class="about col-12 col-md-5"  data-aos="fade-up" data-aos-delay="400">
            Elite Movers Club 132 has been created to consolidate all the
            services provided by the team at one place, so anyone can get
            valuable information as soon as needed.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<template>
  <nav id="topmenu"  class="navbar navbar-expand-lg navbar-dark fixed-top menu">
  <div class="container">
    <a class="navbar-brand" href="#"><img class="logo" :src="require('@/assets/logo.svg')"/></a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#menu" aria-controls="menu" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-end" id="menu">
      <ul class="navbar-nav mb-2 mb-lg-0">
        <li class="nav-item" >
          <a class="nav-link" @click="toggle()" href="#deep-move">Deep Move</a>
        </li>
        <li class="nav-item" >
          <a class="nav-link" @click="toggle()" href="#gocraft">GoCraft</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" @click="toggle()" href="#EMYC">EMYC Chat</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" @click="toggle()" href="#collection">EMYC NFT</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" @click="toggle()" href="#guide">Stepn.guide</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" @click="toggle()" href="#team">Team</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" @click="toggle()" href="#contact">Contact</a>
        </li>
        
      </ul>
    </div>
  </div>
</nav>
</template>

<script setup>
function toggle() {
  const menu = document.getElementById("menu");
  menu.classList.remove("show");
}</script>